import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00e76881"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "square-item cursor-pointer",
  style: { width: '100%', height: '100%' }
}
const _hoisted_2 = {
  key: 0,
  class: "description-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.image !== '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["square-photo-content", [_ctx.customClass]])
        }, [
          _createElementVNode("div", {
            class: "product-img bg-image-style",
            style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.image + ')', width: _ctx.width })
          }, null, 4)
        ], 2),
        _renderSlot(_ctx.$slots, "description", {}, () => [
          (_ctx.title !== '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true)
        ], true)
      ]))
    : _createCommentVNode("", true)
}