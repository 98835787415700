import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "swiper-navigation-btn select-none"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwiperNavBtn = _resolveComponent("SwiperNavBtn")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["g-swiper-square-item", _ctx.customClass])
  }, [
    (_ctx.selectItemRef.length > 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_SwiperNavBtn, {
            onNextClick: _ctx.nextClick,
            onPrevClick: _ctx.prevClick
          }, null, 8, ["onNextClick", "onPrevClick"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Swiper, {
      class: "swiper",
      modules: _ctx.modules,
      scrollbar: { hide: false, draggable: true },
      onSwiper: _ctx.setControlledSwiper,
      autoplay: _ctx.autoPlay,
      navigation: {
        hiddenClass: 'swiper-button-hidden',
        prevEl: 'g-swiper-nav-prev',
        nextEl: 'g-swiper-nav-next',
      }
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "editImage"),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectItemRef, (image, index) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, {
            key: index,
            class: "slide hidden sm:block"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                id: "IMG_2",
                style: _normalizeStyle({ height: _ctx.height, width: _ctx.height }),
                src: image.Image.PcWeb
              }, null, 12, _hoisted_2)
            ]),
            _: 2
          }, 1024))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectItemRef, (image, index) => {
          return (_openBlock(), _createBlock(_component_SwiperSlide, {
            key: index,
            class: "slide sm:hidden"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                id: "IMG_2",
                style: { height: `100%`, width: `100%` },
                src: image.Image.MobileWeb
              }, null, 8, _hoisted_3)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 3
    }, 8, ["modules", "onSwiper", "autoplay"])
  ], 2))
}