
import {
  defineComponent,
  ref,
  onMounted,
  provide,
  toRefs,
  computed,
  onBeforeMount,
  nextTick
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Header from '@/components/Header.vue'
import MobileHeader from '@/components/mobile/Header.vue'
import Footer from '@/components/Footer.vue'
import LoginModal from '@/components/modal/LoginModal.vue'
import DefaultLoader from '@/components/common/DefaultLoader.vue'
import { initFacebook } from '@/plugins/fb'
import store from '@/composition/store'
import productStore from '@/composition/productStore'
import cartStore from '@/composition/cartStore'
import ActivityDetailModal from '@/components/modal/ActivityDetailModal.vue'
import ActivityListModal from '@/components/modal/ActivityListModal.vue'
import NoticeModal from '@/components/modal/NoticeModal.vue'
import Icon from '@/components/common/Icon.vue'
import EventBus from '@/event_bus/eventBus'
import request from '@/utils/request'
import {
  getActivity,
  getActivityList,
  getAdsList3
} from '@/utils/requestUrl'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
  components: {
    Header,
    Footer,
    LoginModal,
    MobileHeader,
    DefaultLoader,
    ActivityDetailModal,
    Icon,
    ActivityListModal,
    NoticeModal
  },
  setup () {
    if (!window.FB) {
      initFacebook()
    }
    provide('store', store)
    provide('productStore', productStore)
    provide('cartStore', cartStore)
    const fixedIconShow = ref(true)
    const fixedIconIndex = ref(1)
    const fixedIconName = ref('activity-1')
    const activityListModalVisible = ref(false)
    const activityDetailModalVisible = ref(false)
    const noticeModalVisible = ref(false)
    const noticeImageUrl = ref('')
    const activityDetailModalRef = ref(null)
    const activityListModalRef = ref(null)
    const activityList = ref([])

    let reditectUrl = ''
    const openUrl = () => {
      window.open(reditectUrl, '_blank')
    }

    setInterval(() => {
      if (fixedIconIndex.value > 5) {
        fixedIconIndex.value = 1
      }

      if (fixedIconIndex.value === 1) {
        reditectUrl = 'https://horlay.com/promotionList?promotionId=8c6251d3-0f88-4c33-ac95-6241a5ebffbf'
      }

      if (fixedIconIndex.value === 2) {
        reditectUrl = 'https://horlay.com/promotionList?promotionId=7c500fbc-be41-499d-b3d1-306e8f3d9c00'
      }

      if (fixedIconIndex.value === 3) {
        reditectUrl = 'https://horlay.com/promotionList?promotionId=cb4f3ed8-9d23-4096-b1c8-cc7b920da3bc'
      }

      if (fixedIconIndex.value === 4) {
        reditectUrl = 'https://horlay.com/promotionList?promotionId=c818bc0c-80a2-403e-aa7d-360b9ba709b9'
      }

      if (fixedIconIndex.value === 5) {
        reditectUrl = 'https://horlay.com/promotionList?promotionId=f9d9a63f-eadf-4b19-9dbe-5b822295b6d4'
      }

      fixedIconName.value = 'activity-' + fixedIconIndex.value
      fixedIconIndex.value++
    }, 1500)
    const couponList = ref([])
    const route = useRoute()
    const router = useRouter()

    const openActivityListModal = async () => {
      try {
        store.setShowLoading(true)
        const res = await request.post(window._env_.VUE_APP_STOREBOOK_SERVICE_API + getActivityList, { Data: { ShopCode: 'G-Gift' } })
        activityList.value = res.data.Data.ActivityList
        couponList.value = res.data.Data.CmdyCouponList
        activityListModalVisible.value = true
        store.setShowLoading(false)

        if (activityListModalRef.value) {
          (activityListModalRef.value as any).createConfirm()
        }
      } catch (error) {
        store.setShowLoading(false)
      }
    }
    window.onload = () => {
      document.addEventListener('touchstart', (event) => {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      let lastTouchEnd = 0
      document.addEventListener('touchend', (event) => {
        const now = new Date().getTime()
        if (now - lastTouchEnd <= 300) {
          event.preventDefault()
        }
        lastTouchEnd = now
      })
      document.addEventListener('gesturestart', (event) => {
        event.preventDefault()
      })
    }
    // 檢查是否需要顯示公告
    const fetchNoticeData = () => {
      const data = {
        Data: {
          ShopCode: 'G-Gift',
          ShopHallCode: '',
          PositionType: 'Home-Popup'
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          getAdsList3,
          data)
        .then((res) => {
          const data = res.data.Data
          if (data && data.AdsList && data.AdsList.length > 0) {
            noticeImageUrl.value = data.AdsList[0].Image
            // 檢查 sessionStorage，決定是否顯示公告
            const hideNotice = sessionStorage.getItem('hideNotice')
            if (!hideNotice) {
              noticeModalVisible.value = true
            }
          }
        })
        .catch((error) => {
          console.error(error.response.data.Message)
        })
    }
    onMounted(fetchNoticeData)
    onMounted(async () => {
      await router.isReady()
      store.setShowLogo(false)

      store
        .fetchUserInfo()
        .then(() => {
          cartStore.fetchShoppingCartList()
        })
        .catch(() => {
          cartStore.fetchShoppingCartList()
        })
      cartStore.fetchDeliveryTypeList()
      cartStore.fetchDeliveryShopList()
      cartStore.fetchPaymentTypeList()
      cartStore.fetchInvoiceTypeList()
      cartStore.fetchPurchaseStatusList()
    })
    onMounted(() => {
      setTimeout(() => {
        document.dispatchEvent(new Event('render-event'))
      }, 3000)

      EventBus.on('openActivityModal', (activityID) => {
        if (activityDetailModalRef.value) {
          (activityDetailModalRef.value as any).createConfirm(activityID)
        }
      })
    })

    return {
      store,
      activityDetailModalRef,
      activityListModalRef,
      fixedIconShow,
      couponList,
      fixedIconName,
      openActivityListModal,
      activityListModalVisible,
      activityDetailModalVisible,
      noticeModalVisible,
      noticeImageUrl,
      openUrl,
      activityList
    }
  }
})
