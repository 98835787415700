import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fd8e456"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative flex flex-col items-center" }
const _hoisted_2 = { class: "notice-content flex justify-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex w-full items-center justify-end mt-[0.5rem] mr-[-1rem]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_ModalBase = _resolveComponent("ModalBase")!

  return (_openBlock(), _createBlock(_component_ModalBase, {
    show: _ctx.show,
    class: "notice-modal"
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Icon, {
          iconName: "close_light",
          class: "absolute top-[5px] right-[5px] z-10 cursor-pointer",
          onClick: _ctx.closeModal
        }, null, 8, ["onClick"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.imageUrl,
            style: _normalizeStyle({
              width: _ctx.isMobile().any ? '100%' : '800px',
              height: 'auto'
            }),
            alt: "公告圖片"
          }, null, 12, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Checkbox, {
            modelValue: _ctx.neverShow,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.neverShow) = $event)),
            class: "text-sm text-light-gary-color",
            text: "不再顯示"
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}